<template>
  <v-dialog v-model="dialog" max-width="650px" @click:outside="close">
    <v-card :loading="loading">
      <template slot="progress">
        <v-progress-linear
          background-color="primary"
          color="white"
          indeterminate
          bottom
        ></v-progress-linear>
      </template>
      <v-card-title>
        <span class="headline">Kommentare</span>
        <v-spacer />
        <v-btn @click="close" icon>
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div v-if="authorComment.comment.length > 0">
          <v-card class="elevation-2 mx-4 mt-5" color="primary" dark shaped>
            <v-card-text>
              <div style="white-space: pre-wrap;">
                {{ authorComment.comment }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <div class="mr-3">
                <small>{{ authorComment.author }}</small>
              </div>
            </v-card-actions>
          </v-card>
          <div class="mx-4 comment-date">
            <small>{{ authorComment.createdAt | luxon("DD T") }}</small>
          </div>
        </div>
        <div v-for="comment in comments" :key="comment.id">
          <v-card class="elevation-2 mx-4 mt-5" color="accent" shaped>
            <v-card-text>
              <v-row>
                <v-col cols="11">
                  <div style="white-space: pre-wrap;">
                    {{ comment.text }}
                  </div>
                </v-col>
                <v-col cols="1">
                  <v-icon v-if="comment.is_pinned" class="pinComment">
                    mdi-pin
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <div class="mr-3">
                <small
                  >{{ comment.author_data.first_name }}
                  {{ comment.author_data.last_name }}</small
                >
              </div>
            </v-card-actions>
          </v-card>
          <div class="mx-4 comment-date">
            <small>{{ comment.created_at | luxon("DD T") }}</small>
          </div>
        </div>

        <div
          v-if="comments.length === 0 && !authorComment.comment.length > 0"
          class="text-center my-10"
        >
          <v-icon size="150">mdi-comment-search-outline</v-icon>
          <div>Noch keine Kommentare vorhanden.</div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory"

const ArticlesRepository = RepositoryFactory.get("articles")

export default {
  name: "DialogPreviewText",
  props: {
    dialog: Boolean,
    articleId: Number,
  },
  data() {
    return {
      loading: false,
      authorComment: {
        comment: "",
        author: "",
        createdAt: "",
      },
      comments: [],
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      const { data: articleData } = await ArticlesRepository.getArticle(
        this.articleId
      )
      this.authorComment.comment = articleData.comment_author
        ? articleData.comment_author
        : ""
      this.authorComment.author =
        articleData.writer_data.first_name +
        " " +
        articleData.writer_data.last_name
      this.authorComment.createdAt = articleData.created_at
      const { data: commentsData } = await ArticlesRepository.getComments(
        this.articleId
      )
      this.comments = commentsData
      this.sortByPinned()
      this.loading = false
    },
    sortByPinned() {
      this.comments.sort(function(x, y) {
        return y.is_pinned - x.is_pinned
      })
    },
    close() {
      this.comments = []
      this.authorComment = {
        comment: "",
        author: "",
        createdAt: "",
      }
      this.$emit("close", this.editedItem)
    },
  },
  created() {},
  watch: {
    dialog(visible) {
      if (visible) {
        this.fetch()
      }
    },
  },
}
</script>

<style scoped>
.comment-date {
  color: gray;
}

.pinComment {
  rotate: 45deg;
  margin-top: -12px;
}
</style>
