<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Tag
            </th>
            <th class="text-right">Bibellese</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in days" :key="row.day">
            <td>{{ row.day }}. {{ month.name }}</td>
            <td class="text-right">
              {{ row.entry ? row.entry.recommended_bible_reference : "" }}
            </td>
            <td class="text-right">
              <v-btn @click="openDialog(month, row.day, row.entry)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "ReadingPlanMonth",
  props: {
    month: Object,
    leapYearShift: Number,
    readingPlanEntries: Array,
  },
  data() {
    return {
      days: [],
    }
  },
  async mounted() {
    if (this.year == 0) {
      this.months[1].length = 29
    }
    for (let day = 1; day <= this.month.length; day++) {
      this.days.push({
        day,
        entry: this.readingPlanEntries.find((e) => e.day == day),
      })
    }
  },
  methods: {
    openDialog(month, day, plan = false) {
      this.$emit("planSelected", month, day, plan)
    },
  },
  watch: {
    readingPlanEntries(newValue) {
      this.days = []
      for (let day = 1; day <= this.month.length; day++) {
        this.days.push({
          day,
          entry: newValue.find((e) => e.day == day),
        })
      }
    },
  },
}
</script>
