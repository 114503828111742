<template>
  <v-app id="inspire">
    <v-main :class="`${projectAbbr}-full-cover-bg`">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card
              class="mx-auto elevation-12"
              max-width="350px"
              :loading="loading"
            >
              <template slot="progress">
                <v-progress-linear
                  background-color="primary"
                  color="white"
                  indeterminate
                  bottom
                />
              </template>

              <v-form class="login" @submit.prevent="login">
                <v-toolbar color="primary" dark flat class="rounded-t">
                  <v-toolbar-title class="mx-auto text-lg-h5"
                    >{{
                      __getString("ABBREVIATION")
                    }}
                    Redaktion</v-toolbar-title
                  >
                </v-toolbar>

                <v-card-text class="px-5 py-5">
                  <p>
                    Bitte melden Sie sich mit Ihren Zugangsdaten am System an.
                    Sollten Sie keine Zugangsdaten erhalten haben, schreiben Sie
                    uns gerne eine Nachricht an
                    <a :href="`mailto:${__getString('EMAIL')}`">{{
                      __getString("EMAIL")
                    }}</a
                    >.
                  </p>

                  <v-text-field
                    label="Benutzername"
                    required
                    v-model="username"
                    id="username"
                    name="username"
                    prepend-icon="mdi-account"
                    type="text"
                    autocomplete="username"
                  />

                  <v-text-field
                    id="password"
                    required
                    v-model="password"
                    label="Passwort"
                    name="password"
                    prepend-icon="mdi-lock"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @mousedown="showPassword = true"
                    @mouseup="showPassword = false"
                    autocomplete="current-password"
                  />
                </v-card-text>

                <v-card-actions>
                  <v-btn block depressed color="primary" type="submit"
                    >Anmelden</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex"
import projectMixin from "@/mixins/projectMixin"

export default {
  name: "Login",
  mixins: [projectMixin],
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "Die Anmeldung ist leider fehlgeschlagen.",
      loading: false,
      showPassword: false,
      projectAbbr: process.env.VUE_APP_PROJECT_ID,
    }
  },
  methods: {
    login: function() {
      this.loading = true
      let username = this.username
      let password = this.password
      this.$store
        .dispatch("login", { username, password })
        .then()
        .catch((err) => {
          console.log(err)
          this.$store.dispatch("setMessage", this.errorMessage)
          this.loading = false
        })
    },
  },
  computed: mapState(["status"]),
  watch: {
    status(newValue) {
      if (newValue === "success") {
        this.$store.dispatch("getUserData")
      }
      if (newValue === "ready") {
        this.$store.dispatch("setMessage", "Herzlich Willkommen")
        this.$vuetify.theme.dark = this.$store.state.user.dark_mode
        this.loading = false
        if (
          !this.$store.state.redirect ||
          this.$store.state.redirect === "/login"
        ) {
          this.$router.push({ path: "/" })
        } else {
          this.$router.push({ path: this.$store.state.redirect.path })
          this.$store.dispatch("setRedirect", false)
        }
      }
    },
  },
}
</script>

<style>
.lim-full-cover-bg {
  background-image: url("../assets/lim_login_bg_01.jpeg");
  background-size: cover;
}
.hs-full-cover-bg {
  background-image: url("../assets/hs_login_bg_01.jpeg");
  background-size: cover;
}
</style>
