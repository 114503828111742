<template>
    <v-app id="inspire">
        <router-view/>
        <MessageService/>
        <DialogProjectSelect v-if="this.$store.getters.isLoggedIn === true && this.$store.getters.isEditor" :projectSelectDialog="projectSelectDialog" @close="closeProjectSelect"/>
    </v-app>
</template>

<script>
    import repository from "./repositories/Repository";
    import MessageService from "./components/services/MessageService";
    import AppSettings from '@/config/AppSettings'
    import store from './store/index';
    import router from './router/index'
    import {Promise} from "es6-promise";
    import DialogProjectSelect from "./components/settings/DialogProjectSelect";
    import {RepositoryFactory} from "./repositories/RepositoryFactory";
    import projectMixin from "@/mixins/projectMixin"

    const ProjectsRepository = RepositoryFactory.get('projects');

    export default {
        name: 'App',
        pageTitle: projectMixin.methods.__getString('TITLE') + ' | Redaktion',
        mixins: [projectMixin],
        components: {
            MessageService,
            DialogProjectSelect,
        },
        data() {
            return {
                projectSelectDialog: false,
                projects: [],
            }
        },
        methods: {
            getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for(let i = 0; i <ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) === ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) === 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            async getUserData() {
                if(store.getters.isLoggedIn) {
                    await store.dispatch('getUserData');
                }
            },
            async checkProjects() {
                this.loading = true;
                ProjectsRepository.getActiveProjects()
                    .then(response => {
                        this.projects = response.data;
                        let currentProject = this.$store.getters.currentProject;
                        if (this.projects.length > 1) {
                            if (!(currentProject === undefined || currentProject === null)){
                                if (!this.isValidProject(currentProject)){
                                    // store.dispatch('setProject', null);
                                    this.projectSelectDialog = true;
                                }
                            } else {
                                this.projectSelectDialog = true;
                            }
                        }
                        else if (this.projects.length === 1) {
                            store.dispatch('setProject', this.projects[0]);
                        }
                        else {
                            if (store.getters.isAdmin) {
                                // store.dispatch('setProject', null);
                                this.projectSelectDialog = true;
                                router.push({path: '/projects', query: {noActiveProject: "true"}});
                            }
                            else {
                                store.dispatch('setProject', null);
                                router.push({path: '/', query: {noActiveProject: "true"}});
                            }
                        }
                    })
            },
            isValidProject(currentProjectId) {
                return this.projects.find(x => x.id === currentProjectId);
            },
            closeProjectSelect(){
                this.projectSelectDialog = false;
            }
        },
        async created() {
            let self = this;
                repository.interceptors.response.use(
                    function (response) {
                        return response;
                    },
                    function (error) {
                        if (!error.response) {
                            // todo: CATCH NETWORK ERRORS WITHOUT RESPONSE
                            console.log("CATCH NETWORK ERROR");
                        } else {
                            if (error.response.status === 401) {
                                if (error.response.config.__isRetryRequest) {
                                    return new Promise((resolve, reject) => {
                                        store.dispatch('setRedirect', self.$route.path);
                                        store.dispatch('logout');
                                        router.push({path: '/login'});
                                        reject(error);
                                    });
                                }

                                if (error.response.config && !error.response.config.__isRetryRequest) {
                                    error.response.config.__isRetryRequest = true;
                                    return store.dispatch('refresh')
                                        .then(function () {
                                            return new Promise((resolve, reject) => {
                                                repository.request(error.response.config)
                                                    .then(response => {
                                                        resolve(response);
                                                    }).catch((error) => {
                                                    reject(error);
                                                })
                                            });
                                        }).catch(error => {
                                            store.dispatch('setRedirect', self.$route.path);
                                            store.dispatch('logout');
                                            router.push({path: '/login', message: error});
                                        });
                                } else {
                                    store.dispatch('setRedirect', self.$route.path);
                                    store.dispatch('logout');
                                    router.push({path: '/login'});
                                }
                            } else if (error.response.status >= 400 && error.response.status < 500) {
                                store.dispatch('setMessage', "Es ist ein Fehler (" + error.response.status + ") aufgetreten.<br>" + JSON.stringify(error.response.data));
                                console.log(error.response);
                            } else if (error.response.status >= 500 && error.response.status < 600) {
                                store.dispatch('setMessage', 'Es ist ein Server Fehler (' + error.response.status + ') aufgetreten.');
                                console.log(error.response);
                            } else {
                                store.dispatch('setMessage', 'Es ist ein Fehler (' + error.response.status + ') aufgetreten.');
                                console.log(error.response);
                            }
                        }
                    });

                // set dark mode to theme from vuex or cookie
                if (this.$store.state.user.dark_mode !== undefined) {
                    this.$vuetify.theme.dark = this.$store.state.user.dark_mode;
                } else {
                    if (this.getCookie(AppSettings.LOCAL_STORAGE_PREFIX + "_DarkMode") !== "") {
                        this.$vuetify.theme.dark = JSON.parse(this.getCookie(AppSettings.LOCAL_STORAGE_PREFIX + "_DarkMode"));
                    } else {
                        this.$vuetify.theme.dark = false;
                    }
                }

            await this.getUserData();
        },
        watch: {
            '$store.getters.currentProject': function() {
                if (this.$store.getters.loginFlag && this.$store.getters.isLoggedIn && (this.$store.getters.isAdmin || this.$store.getters.isEditor)) {
                    store.dispatch('setLoginFlag', false);
                    this.checkProjects();
                }
            }
        }
    }
</script>

<style lang="sass">
    @import '../node_modules/typeface-roboto/index.css'
</style>

<style>
    .theme--dark.v-application {
        background-color: #1E1E1E;
    }

    @font-face {
        font-family: "Cambria";
        src: local("Cambria"),   url(./assets/Cambria.woff) format("truetype");
    }

    @font-face {
        font-family: "Humanist";
        src: local("Humanist"),   url(./assets/Humanist-Black.ttf) format("truetype");
    }
    /* latin */
    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-display: swap;
        src: local("Humanist"),   url(./assets/Montserrat-Latin.woff2) format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-display: swap;
        src: local("Humanist"),   url(./assets/Montserrat-Latin-Ext.woff2) format("woff2");
        unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: "Milo";
        src: local("Milo"),   url(./assets/MiloSerifOT-Text.otf) format("opentype");
    }

    @font-face {
        font-family: "Milo-Italic";
        src: local("Milo-Italic"),   url(./assets/MiloSerifOT-TextItalic.otf) format("opentype");
    }

    @font-face {
        font-family: "Wurper-Regular";
        src: local("Wurper-Regular"), url(./fonts/wurper-regular-font/WurperRegular-pyZZ.ttf) format("truetype");
    }

    @font-face {
        font-family: "Vanilla";
        src: local("Vanilla"), url(./fonts/Vanilla/Vanilla.ttf) format("truetype");
    }

    @font-face {
        font-family: "Lato-Bold";
        src: local("Lato-Bold"), url(./fonts/lato/Lato-Bold.ttf) format("truetype");
    }

    @font-face {
        font-family: "Lato-Regular";
        src: local("Lato-Regular"), url(./fonts/lato/Lato-Regular.ttf) format("truetype");
    }

    .link-indicator {
        color: var(--v-primary-base);
        cursor: pointer;
    }

    .article-text-preview {
        white-space: pre-wrap;
    }
    .article-text-preview p:after {
        content:"";
        display:inline-block;
        width:0;
    }
</style>
