var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('MenuSetDefault'),_c('v-main',[_c('v-card',{staticClass:"elevation-0",attrs:{"flat":"","tile":""}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":"","color":"accent"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-left")])],1),_c('v-toolbar-title',[_vm._v("Beiträge von "+_vm._s(_vm.user.full_name))]),_c('v-spacer'),(_vm.instanceData.hasFreeArticleSubmission)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.newItem}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,4170415070)},[_c('span',[_vm._v("Beitrag erstellen")])]),_c('DialogArticleNew',{attrs:{"dialog":_vm.newArticleDialog},on:{"close":_vm.close,"saved":_vm.saved}})]:_vm._e()],2),_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.tableSettings.search),callback:function ($$v) {_vm.$set(_vm.tableSettings, "search", $$v)},expression:"tableSettings.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.articles,"search":_vm.tableSettings.search,"loading":_vm.loading,"loading-text":"Daten werden geladen... Bitte warten","footer-props":{
                    'items-per-page-options': [10, 25, 50, -1]},"options":_vm.tableSettings,"custom-sort":_vm.customSort},on:{"update:options":_vm.updateTableSettings},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('DialogPreviewFull',{attrs:{"dialog":_vm.preview_dialog,"article_id":_vm.preview_id},on:{"close":_vm.close}}),_c('DialogArticleEdit',{attrs:{"dialog":_vm.editDialog,"article_id":_vm.editId},on:{"edited":_vm.edited,"close":_vm.close,"intermediateUpdate":_vm.updateArticle}})]},proxy:true},{key:"item.topic",fn:function(ref){
                    var item = ref.item;
return [(_vm.instanceData.hasReadingPlan)?[_c('div',{staticStyle:{"line-height":"16px"}},[_c('strong',{staticStyle:{"font-size":"1rem"}},[_vm._v(_vm._s(item.content_topic_name))]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(item.topic_name)+" "+_vm._s(item.sub_topic != null ? " > " + item.sub_topic_name : "")+" ")])]:[_vm._v(" "+_vm._s(item.topic_name)+" "+_vm._s(item.sub_topic != null ? " > " + item.sub_topic_name : "")+" ")]]}},{key:"item.assigned_date",fn:function(ref){
                    var item = ref.item;
return [(item.assigned_date)?_c('span',[_vm._v(" "+_vm._s(_vm._f("luxon")(item.assigned_date))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.writer_data",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.writer_name)+" ")]}},{key:"item.title",fn:function(ref){
                    var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.title))])]}},{key:"item.votd_reference",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseBibleRef(item.votd_reference, item.votd_reference_overwrite))+" ")]}},{key:"item.id",fn:function(ref){
                    var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Beitrag bearbeiten")])],1)],1),_c('v-list-item',{attrs:{"disabled":!(item.status === _vm.AppSettings.STATUS_FINAL)},on:{"click":function($event){return _vm.previewItem(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-eye ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Vorschau")])],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }