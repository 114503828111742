const APP_TITLE = "Redaktion";
const LOCAL_STORAGE_PREFIX = process.env.VUE_APP_PROJECT_ID + "App";

const STATUS_SUBMITTED = 10;
const STATUS_DEFERRED = 70;
const STATUS_REVISED = 20;
const STATUS_APPROVED = 30;
const STATUS_FINAL = 40;
const STATUS_DISCARDED = 90;

export default {
    APP_TITLE: APP_TITLE,
    LOCAL_STORAGE_PREFIX: LOCAL_STORAGE_PREFIX,
    STATUS_SUBMITTED: STATUS_SUBMITTED,
    STATUS_DEFERRED: STATUS_DEFERRED,
    STATUS_REVISED: STATUS_REVISED,
    STATUS_APPROVED: STATUS_APPROVED,
    STATUS_FINAL: STATUS_FINAL,
    STATUS_DISCARDED: STATUS_DISCARDED,
}
