var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('MenuSetDefault'),_c('v-main',[_c('v-card',{staticClass:"elevation-0"},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":"","color":"accent"}},[_c('v-toolbar-title',[_vm._v("Bibelübersetzungen")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","color":"primary"},on:{"click":function($event){return _vm.editItem()}}},'v-btn',tooltipAttrs,false),tooltip),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Übersetzung erstellen")])])],1),_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Daten werden geladen... Bitte warten","headers":_vm.headers,"items":_vm.translations,"items-per-page":15,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('DialogBibleTranslation',{attrs:{"dialog":_vm.dialog,"editedItem":_vm.editedItem,"formTitle":_vm.formTitle,"editedIndex":_vm.editedIndex},on:{"saved":_vm.saved,"close":_vm.close}})]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Übersetzung bearbeiten")])],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }