import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

import de from 'vuetify/lib/locale/de'

const themes = {
    lim: {
        light: {
            primary: '#c01500',
            secondary: '#b0bec5',
            accent: '#f4f4f4',
            error: '#b71c1c',
        },
        dark: {
            primary: '#c01500',
            secondary: '#b0bec5',
            accent: '#363636',
            error: '#b71c1c',
        }
    },
    hs: {
        light: {
            primary: '#4a74b8',
            secondary: '#b0bec5',
            accent: '#f4f4f4',
            error: '#b71c1c',
        },
        dark: {
            primary: '#4a74b8',
            secondary: '#b0bec5',
            accent: '#363636',
            error: '#b71c1c',
        }
    }
}

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: { de },
        current: 'de',
    },
    theme: {
        themes: themes[process.env.VUE_APP_PROJECT_ID]
    },
},
);
