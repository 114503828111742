<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          Arbeitstitel für das Thema "{{ topic.name }}" anzeigen
        </span>
      </v-card-title>

      <v-card-text>
        <template v-if="contentTopics.length > 0">
          <v-chip
            v-for="contentTopic in contentTopics"
            :key="contentTopic.id"
            class="ma-1"
          >
            {{ contentTopic.name }}
          </v-chip>
        </template>
        <template v-else>
          Diesem Thema wurden bisher keine Arbeitstitel zugeordnet
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="close" text>Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory"

const TopicsRepository = RepositoryFactory.get("topics")

export default {
  name: "DialogNewContentTopics",
  props: {
    dialog: Boolean,
    topic: Object,
  },
  components: {},
  mixins: [],
  data() {
    return {
      contentTopics: [],
      // topic: {},
    }
  },
  async mounted() {
    const {
      data: contentTopicsData,
    } = await TopicsRepository.getContentTopics()
    if (this.topic.topic) {
      this.contentTopics = contentTopicsData.filter(
        (ct) => ct.sub_topic == this.topic.id
      )
    } else {
      this.contentTopics = contentTopicsData.filter(
        (ct) => ct.topic == this.topic.id
      )
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
  },
}
</script>

<style scoped></style>
