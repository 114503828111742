<template>
  <v-card class="flex flex-column my-3 mb-6">
    <v-toolbar color="transparent" class="elevation-0">
      <v-toolbar-title>Meine geplanten Beiträge</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon class="mr-4" @click="plannedArticlesDialog = true">
        <v-icon>mdi-text-box-multiple</v-icon>
      </v-btn>
    </v-toolbar>

    <v-skeleton-loader
      :loading="loading"
      type="table-heading, list-item-two-line, divider, list-item-two-line, divider, list-item-two-line"
    >
      <v-card-text>
        <v-simple-table class="my-0" primary v-if="!loading">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Datum
                </th>
                <th class="text-left">
                  Arbeitstitel
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="article in articles" :key="article.id">
                <td class="text-left">
                  {{
                    new Date(article.assigned_date).toLocaleDateString("en-CH")
                  }}
                </td>
                <td class="text-left">
                  <template v-for="topic in topics">
                    {{ topic.id == article.content_topic ? topic.name : "" }}
                  </template>
                </td>
                <td class="text-center">
                  <v-btn @click="createArticle(article)">
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-skeleton-loader>

    <DialogArticleNew
      :dialog="newArticleDialog"
      :selectedPlan="selectedPlan"
      @saved="close"
      @close="close"
    />
    <DialogPlannedArticles
      :dialog="plannedArticlesDialog"
      :selectedProject="selectedProject"
      @close="close"
    />
  </v-card>
</template>

<script>
import DialogArticleNew from "@/components/articles/DialogArticleNew"
import DialogPlannedArticles from "@/components/dashboard/DialogPlannedArticles"

import { RepositoryFactory } from "@/repositories/RepositoryFactory"
const TopicsRepository = RepositoryFactory.get("topics")
const PlannedArticlesRepository = RepositoryFactory.get("plannedArticles")

export default {
  name: "PlannedArticlesWidget",
  props: {
    loading: Boolean,
    selectedProject: Object,
  },
  components: {
    DialogArticleNew,
    DialogPlannedArticles,
  },
  data() {
    return {
      selectedPlan: {},
      newArticleDialog: false,
      plannedArticlesDialog: false,
      allPlannedArticles: [],
      articles: [],
      topics: [],
      authors: [],
    }
  },
  async created() {
    const { data: topicsData } = await TopicsRepository.getContentTopics()
    this.topics = topicsData

    const {
      data: plannedArticlesData,
    } = await PlannedArticlesRepository.getByWriter(this.$store.state.user.id)
    this.allPlannedArticles = plannedArticlesData
    this.articles = plannedArticlesData
      .filter((article) => article.project == this.selectedProject.id)
      .filter((article) => article.reused_article == null)
  },
  watch: {
    selectedProject: function() {
      this.articles = this.allPlannedArticles.filter(
        (article) => article.project == this.selectedProject.id
      )
    },
  },
  methods: {
    createArticle(article) {
      this.selectedPlan = article
      this.newArticleDialog = true
    },
    async close() {
      this.$emit("dialogClosed")
      this.newArticleDialog = false
      this.plannedArticlesDialog = false
      this.selectedPlan = {}

      const {
        data: plannedArticlesData,
      } = await PlannedArticlesRepository.getByWriter(this.$store.state.user.id)

      this.articles = plannedArticlesData
        .filter((article) => article.project == this.selectedProject.id)
        .filter((article) => article.reused_article == null)
    },
  },
}
</script>

<style scoped></style>
