import Repository from "./Repository";

const resource = "/reading_plans";

export default {
    get() {
        return Repository.get(`${resource}/`);
    },
    getByID(ID) {
        return Repository.get(`${resource}/${ID}/`);
    },
    getByDate(date) {
        return Repository.get(`${resource}/by_date/?date=${date}`);
    },
    add(leapYearShift, month, day, recommendedBibleRef) {
        let payload = { "leap_year_shift": leapYearShift, "month": month, "day": day, "recommended_bible_reference": recommendedBibleRef }

        return Repository.post(`${resource}/`, payload);
    },
    update(ID, leapYearShift, month, day, recommendedBibleRef) {
        let payload = { "leap_year_shift": leapYearShift, "month": month, "day": day, "recommended_bible_reference": recommendedBibleRef }

        return Repository.put(`${resource}/${ID}/`, payload)
    }
}