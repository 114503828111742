<template>
    <div>
        <v-btn v-if="!loading && !article.project_data"
               :disabled="!this.$store.getters.currentProjectMeta.editable"
               color="primary"
               block
               class="mb-4 mt-1"
               @click="addToProject">
            Dem Projekt "{{ this.$store.getters.currentProjectMeta.name }}" zuordnen
        </v-btn>

        <v-card class="flex flex-column ma-1">
            <v-toolbar color="accent" class="elevation-0" dense>
                <v-tabs v-model="tab">
                    <v-tabs-slider color="primary"></v-tabs-slider>

                    <v-tab
                            v-for="item in items"
                            :key="item"
                    >
                        {{ item }}
                    </v-tab>
                </v-tabs>
            </v-toolbar>

            <v-tabs-items v-model="tab">
                <v-tab-item
                        key="Information"
                >
                    <v-card flat>
                        <v-card-text>

                            <v-skeleton-loader
                                    :loading="loading"
                                    type="list-item-two-line, divider, list-item-two-line, divider, list-item-two-line, list-item-two-line, actions"
                            >
                                <v-list dense>
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-shape</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title v-if="!!article.topic_data">
                                                {{ article.topic_data.name }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else>-</v-list-item-title>
                                            <v-list-item-subtitle>Thema</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider inset></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-folder-open</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title v-if="!!article.project_data">
                                                {{ article.project_data.name }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else>-</v-list-item-title>
                                            <v-list-item-subtitle>Projekt</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" icon
                                                       :disabled="!article.project_data || !projectMeta.editable"
                                                       @click="dialogRemoveProject = true">
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Aus Projekt entfernen</span>
                                        </v-tooltip>

                                        <DialogRemoveArticleProject
                                                :dialogRemove="dialogRemoveProject"
                                                :article="article"
                                                :project="projectMeta"
                                                dialogTitle="Beitrag aus Projekt entfernen?"
                                                @removeArticleConfirm="removeArticleConfirm"
                                                @closeDelete="closeDialog"
                                        />


                                    </v-list-item>

                                    <v-divider inset></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-calendar</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title v-if="!!article.assigned_date">
                                                {{ article.assigned_date | luxon }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else>-</v-list-item-title>
                                            <v-list-item-subtitle>Datum</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" icon
                                                       :disabled="!article.project_data || !projectMeta.editable"
                                                       @click="editArticleDate">
                                                    <v-icon>mdi-calendar-edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Datum ändern</span>
                                        </v-tooltip>

                                        <DialogEditArticleDate
                                                v-if="!!article.project_data"
                                                :dialogEditDate="dialogEditDate"
                                                :article="article"
                                                :projectId="article.project_data.id"
                                                dialogTitle="Beitrag Datum festlegen"
                                                @editDateConfirm="editDateConfirm"
                                                @closeDialog="closeDialog"
                                        />

                                    </v-list-item>

                                    <v-divider inset></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-calendar-star</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title v-if="!!article.event_day_data || !!article.event_day_topic_data">
                                                <span v-if="!!article.event_day_data">{{ article.event_day_data.name }}</span>
                                                <span v-if="article.event_day_data && article.event_day_topic_data"> | </span>
                                                <span v-if="!!article.event_day_topic_data">{{ article.event_day_topic_data.name }}</span>
                                            </v-list-item-title>
                                            <v-list-item-title v-else>-</v-list-item-title>
                                            <v-list-item-subtitle>Ereignis</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-account-circle</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span v-if="article.id !== undefined">{{ article.writer_data.first_name }} {{ article.writer_data.last_name}}</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Autor</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" icon
                                                           :to="`/profile/` + article.writer">
                                                        <v-icon>mdi-eye</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Profil anzeigen</span>
                                            </v-tooltip>
                                        </v-list-item-icon>
                                    </v-list-item>

                                    <v-divider></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-list-status</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title>{{ article.status_name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Status</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider inset></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-calendar-edit</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title>{{ article.created_at | luxon }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Erstellungsdatum</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider inset></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-calendar-refresh</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title>{{ article.updated_at | luxon }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Änderungsdatum</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider inset></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-account-edit</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title v-if="!!article.updated_user">
                                                {{ article.updated_user_data.first_name }} {{ article.updated_user_data.last_name }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else>-</v-list-item-title>
                                            <v-list-item-subtitle>Letzter Bearbeiter</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>


                                </v-list>

                            </v-skeleton-loader>

                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item key="Checks">
                    <DialogAddArticleCheck
                            :dialogAddCheck="dialogAddCheck"
                            :checkToAdd="checkToAdd"
                            :articleId="article.id"
                            dialogTitle="Beitrag Check hinzufügen"
                            @addCheckConfirm="addCheckConfirm"
                            @closeDialog="closeDialog"
                    />
                    <v-card flat>
                        <v-card-text>
                            <v-checkbox
                                    v-for="check in currentChecks"
                                    v-bind:key="check.id"
                                    :id="check.check_type"
                                    :name="check.check_name"
                                    :label="`${check.check_name}`"
                                    :value="check.active"
                                    v-model="check.active"
                                    @click="addCheck(check)"
                                    :disabled="check.active"
                            >
                            </v-checkbox>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <DialogChangeArticleStatus
                            :dialogChangeStatus="dialogChangeStatus"
                            :statusToSet="statusToSet"
                            :article="article"
                            :statusOptions="statusOptions"
                            dialogTitle="Beitrag Status ändern"
                            @changeStatusConfirm="statusUpdateConfirm"
                            @closeDialog="closeDialog"
                    />
                    <v-card flat>
                        <v-card-text>
                            <v-btn
                                    v-for="(status, index) in statusOptions"
                                    v-bind:key="index"
                                    block
                                    class="mb-2"
                                    v-bind:class="article.status === Number(index) ? 'disable-events' : ''"
                                    v-bind:color="article.status === Number(index) ? 'primary' : ''"
                                    elevation="0"
                                    @click="changeStatus(index)"
                            >
                                {{ status }}
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
    // todo: https://www.tutorialspoint.com/order-an-array-of-words-based-on-another-array-of-words-javascript
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import statusMixin from '@/mixins/statusMixin'
    import DialogAddArticleCheck from "@/components/articles/DialogAddArticleCheck";
    import DialogChangeArticleStatus from "./DialogChangeArticleStatus";
    import DialogEditArticleDate from "./DialogEditArticleDate";
    import DialogRemoveArticleProject from "./DialogRemoveArticleProject"

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "ArticleInformationCard",
        components: {
            DialogRemoveArticleProject,
            DialogEditArticleDate,
            DialogAddArticleCheck,
            DialogChangeArticleStatus,
        },
        props: {
            article: Object,
            loading: Boolean,
        },
        mixins: [statusMixin],
        data() {
            return {
                tab: null,
                items: [
                    'Information', 'Checks', 'Status',
                ],
                articleChecks: [],
                checkOptions: [],
                statusOptions: [],
                currentChecks: [],
                dialogAddCheck: false,
                checkToAdd: {},
                dialogChangeStatus: false,
                statusToSet: '',
                dialogEditDate: false,
                dialogRemoveProject: false,
                currentProject: {},
            }
        },
        methods: {
            async getArticleChecks() {
                const {data} = await ArticlesRepository.getChecks(this.article.id);
                this.articleChecks = data;
                if (this.checkOptions) {
                    this.calculateCurrentChecks();
                }
            },
            async getCheckOptions() {
                const {data} = await ArticlesRepository.getCheckOptions();
                this.checkOptions = data;
                if (this.articleChecks) {
                    this.calculateCurrentChecks();
                }
            },
            async getStatusOptions() {
                const {data} = await ArticlesRepository.getStatusOptions();
                this.statusOptions = data;
            },
            editArticleDate() {
                this.dialogEditDate = true;
            },
            editDateConfirm(article) {
                this.$emit('updateArticle', article);
                this.closeDialog();
            },
            addToProject() {
                ArticlesRepository.setProject(this.article.id, this.$store.getters.currentProject)
                    .then(response => {
                        this.$store.dispatch('setMessage', 'Der Beitrag wurde dem Projekt"' + response.data.project_data.name + '" zugeordnet.');
                        this.$emit('updateArticle', response.data);
                    })
            },
            removeArticleConfirm(data) {
                this.$emit('updateArticle', data);
                this.closeDialog();
            },
            calculateCurrentChecks() {
                let arrayOfObj = Object.entries(this.checkOptions).map((e) => ({check_type: e[0], check_name: e[1]}));
                let currentChecks = [];
                arrayOfObj.forEach(option => {
                        let currentOption = this.articleChecks.find(check => check.active === true && check.check_type === option.check_type) || false;
                        if (currentOption) {
                            currentChecks.push({...option,...currentOption});
                        } else {
                            currentChecks.push({
                                ...option,
                                id: null,
                                user_data: null,
                                active: null,
                                created_at: false,
                                article: null,
                                user: null,
                            });
                        }
                    }
                );
                this.currentChecks = currentChecks;
            },
            addCheck(check) {
                this.dialogAddCheck = true;
                this.checkToAdd = check;
            },
            addCheckConfirm(check) {
                this.getArticleChecks();
                this.closeDialog();
                if (check) {
                    console.log(check)
                    const checkTypes = {
                        'c': 'Inhlt',
                        'e': 'Umfang',
                        's': 'Rechtschreibung',
                        'f': 'Formalien',
                        'p': 'Absätze',
                        'v': 'Tagesvers',
                    }
                    this.$store.dispatch('setMessage', 'Der Check "' + checkTypes[check.check_type] + '" wurde erfolgreich hinzugefügt.');
                } else {
                    this.$store.dispatch('setMessage', 'Beim Hinzufügen des Checks gab es ein Problem.');
                }
            },
            changeStatus(status) {
                this.dialogChangeStatus = true;
                this.statusToSet = status;
            },
            closeDialog() {
                this.dialogAddCheck = false;
                this.checkToAdd = {};
                this.dialogChangeStatus = false;
                this.statusToSet = '';
                this.dialogEditDate = false;
                this.dialogRemoveProject = false;
            },
            statusUpdateConfirm(article) {
                this.closeDialog();
                if (article) {
                    this.$store.dispatch('setMessage', 'Der Status wurde erfolgreich auf "' + this.statusOptions[article.status] + '" geändert.');
                    this.$emit('updateArticle', article);
                } else {
                    this.$store.dispatch('setMessage', 'Beim Ändern des Status gab es ein Problem.');
                }
            },
        },
        created() {
            if (this.article.id !== undefined && this.article.id !== null) {
                this.getCheckOptions();
                this.getArticleChecks();
                this.getStatusOptions();
            }
        },
        computed: {
            projectMeta() {
                return this.$store.getters.currentProjectMeta;
            }
        },
        watch: {
            article: {
                handler() {
                    if (this.article !== undefined) {
                        this.getCheckOptions();
                        this.getArticleChecks();
                        this.getStatusOptions();
                    }
                },
                deep: true
            }
        }
    }
</script>

<style scoped>
    .disable-events {
        pointer-events: none
    }
</style>
