<template>
    <div class="text-center">
        <v-progress-circular
                v-if="loading"
                class="my-10"
                :size="50"
                color="primary"
                indeterminate
        ></v-progress-circular>

        <v-simple-table class="elevation-2 my-5" primary v-if="!loading">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        #
                    </th>
                    <th class="text-left">
                        Ausgangsversion
                    </th>
                    <th class="text-left">
                        Geänderte Version
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="text-left">Benutzer</td>
                    <td v-if="prevState.user_data !== undefined" class="text-left">{{ prevState.user_data.last_name }}, {{ prevState.user_data.first_name }}</td>
                    <td v-else class="text-left">{{ article.writer_data.last_name }}, {{ article.writer_data.first_name }}</td>
                    <td class="text-left">{{ history.user_data.last_name }}, {{ history.user_data.first_name }}</td>
                </tr>
                <tr>
                    <td class="text-left">Speicherdatum</td>
                    <td v-if="prevState.created_at !== undefined" class="text-left">{{ prevState.created_at | luxon("DD T") }}</td>
                    <td v-else class="text-left">{{ article.created_at | luxon("DD T") }}</td>
                    <td v-if="history.is_initial" class="text-left">{{ article.updated_at | luxon("DD T") }}</td>
                    <td v-else class="text-left">{{ history.created_at | luxon("DD T") }}</td>
                </tr>
                <tr>
                    <td class="text-left">Feld</td>
                    <td class="text-left">{{ history.output_field }}</td>
                    <td class="text-left">{{ history.output_field }}</td>
                </tr>

                <tr v-if="history.is_initial">
                    <td class="text-left">Inhalt</td>
                    <td class="text-left">
                        {{
                            ['text', 'votd'].includes(history.field) 
                                ? history.content 
                                : history.output_value
                        }}
                    </td>
                    <td class="text-left">
                        <template v-if="history.output_field == 'Status'">
                            {{ article.status_name }}
                        </template>
                        <template v-else-if="history.output_field == 'Tagesvers Bibelübersetzung'">
                            {{ article.votd_bible_translation_data.name }}
                        </template>
                        <template v-else-if="history.output_field == 'Autor'">
                            {{ article.writer_data.first_name }} {{ article.writer_data.last_name }}
                        </template>
                        <template v-else>
                            {{ article[history.field.replace("fk_", "")] }}
                        </template>
                    </td>
                </tr>
                <tr v-else-if="history.field === 'text' || history.field === 'votd'">
                    <td class="text-left vertical-align-top">Inhalt</td>
                    <td v-if="prevState.content !== undefined" class="text-left vertical-align-top">
                        {{ prevState.content }}
                    </td>
                    <td v-else class="text-left vertical-align-top">N/A</td>
                    <td class="text-left diff-display vertical-align-top"
                        v-html="diffTest(prevState.content, history.content)">
                        {{ prevState.content }}
                    </td>
                </tr>
                <tr v-else>
                    <td class="text-left">Inhalt</td>
                    <td v-if="prevState.output_value !== undefined" class="text-left">{{prevState.output_value}}</td>
                    <td v-else class="text-left">N/A</td>
                    <td class="text-left">{{history.output_value}}</td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
    import { RepositoryFactory } from "@/repositories/RepositoryFactory";

    import TextDiff from "text-diff";

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "ArticleHistoryDetail",
        props: {
            history: Object,
            article: Object,
            source: String,
        },
        data() {
            return {
                prevState: '',
                loading: true,
                }
        },
        methods: {
            async fetch() {
                this.prevState = '';
                this.loading = true;
                const {data} = await ArticlesRepository.getHistoryPrevState(this.history.article, this.history.field, this.history.created_at);
                this.prevState = data;
                this.loading = false;
            },
            diffTest(string1, string2){
                let diff = new TextDiff();
                let result = diff.main(string1, string2);
                diff.cleanupSemantic(result);
                return diff.prettyHtml(result);
            },
        },
        created() {
            this.prevState = '';
            this.fetch();
        },
        watch: {
            history: {
                handler(){
                    this.fetch();
                },
                deep:true
            }
        }

    }
</script>

<style scoped>
.text{
    white-space: pre-line;
}
td.vertical-align-top {
    vertical-align: top;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
</style>

<style>
.diff-display ins{
    background-color: rgba(0, 255, 0, 0.33);
}
.diff-display del{
    background-color: rgba(255, 0, 0, 0.33);
}
</style>
