<template>
  <div>
    <v-tabs v-model="tab" fixed-tabs>
      <v-tab v-for="month in months" :key="month.name">
        {{ month.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="month in months" :key="month.number">
        <ReadingPlanMonth
          :month="month"
          :leapYearShift="leapYearShift"
          :readingPlanEntries="
            readingPlanEntries.filter((e) => e.month == month.number)
          "
          @planSelected="openDialog"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ReadingPlanMonth from "@/components/readingPlan/month"

export default {
  name: "ReadingPlanYear",
  props: {
    leapYearShift: Number,
    readingPlanEntries: Array,
  },
  components: {
    ReadingPlanMonth,
  },
  data() {
    return {
      months: [
        { number: 1, name: "Januar", length: 31 },
        { number: 2, name: "Februar", length: 28 },
        { number: 3, name: "März", length: 31 },
        { number: 4, name: "April", length: 30 },
        { number: 5, name: "Mai", length: 31 },
        { number: 6, name: "Juni", length: 30 },
        { number: 7, name: "Juli", length: 31 },
        { number: 8, name: "August", length: 31 },
        { number: 9, name: "September", length: 30 },
        { number: 10, name: "Oktober", length: 31 },
        { number: 11, name: "November", length: 30 },
        { number: 12, name: "Dezember", length: 31 },
      ],
      tab: 0,
    }
  },
  async created() {
    if (this.leapYearShift === 0) {
      this.months[1].length = 29
    } else {
      this.months[1].length = 28
    }
  },
  methods: {
    openDialog(month, day, plan = false) {
      this.$emit("planSelected", this.leapYearShift, month, day, plan)
    },
  },
}
</script>
