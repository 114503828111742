<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">
                    <v-toolbar-title>Übersicht</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-row class="pa-4">
                    <v-col cols="12" sm="12" md="6" class="pt-1 px-4">
                        <ProjectWidget
                                v-if="this.$store.getters.isLoggedIn === true && this.$store.getters.isEditor"
                                :isAdmin="this.$store.getters.isLoggedIn === true && this.$store.getters.isAdmin"
                                :currentProject="currentProject"
                        />
                        <WidgetLatestArticles
                                v-if="this.$store.getters.isLoggedIn === true && this.$store.getters.isEditor"
                                :articleStats="articleStats"
                                :loading="loading"
                        />
                        <CurrentProjectWidget
                                v-if="this.$store.getters.isLoggedIn === true && this.$store.getters.isEditor"
                                :stats="stats"
                                :articleStats="articleStats"
                                :topics="topics"
                                :project="project"
                                :loading="loading"
                                :progress="progress"
                                :isAdmin="this.$store.getters.isLoggedIn === true && this.$store.getters.isAdmin"
                        />
                        <WidgetDashboardButtons/>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pa-4">
                        <DailyArticleWidget/>
                        <WidgetProjectOverview
                            v-if="this.$store.getters.isLoggedIn === true && this.$store.getters.isAuthor"
                            :loading="loading"
                        />
                    </v-col>
                </v-row>
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import ProjectWidget from '@/components/dashboard/WidgetProjectSelect'
    import DailyArticleWidget from "../components/dashboard/WidgetDailyArticle";
    import WidgetDashboardButtons from "../components/dashboard/WidgetDashboardButtons";
    import CurrentProjectWidget from "../components/dashboard/WidgetCurrentProject";
    import WidgetLatestArticles from "../components/dashboard/WidgetLatestArticles";
    import WidgetProjectOverview from "../components/dashboard/WidgetProjectOverview";

    import projectMixin from '@/mixins/projectMixin'

    const ProjectsRepository = RepositoryFactory.get('projects');
    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "Dashboard",
        components: {
            MenuSetDefault,
            WidgetDashboardButtons,
            ProjectWidget,
            DailyArticleWidget,
            CurrentProjectWidget,
            WidgetLatestArticles,
            WidgetProjectOverview
        },
        mixins: [projectMixin],
        data() {
            return {
                articleStats: {},
                stats: {topics: []},
                project: {},
                loading: false,
                progress: 0,
            }
        },
        created() {
            if (this.$store.getters.isLoggedIn === true && this.$store.getters.isEditor) {
                this.fetch();
                this.fetchStats();
                this.fetchArticleStats();
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await ProjectsRepository.getById(this.currentProject);
                this.project = data;
            },
            async fetchStats() {
                this.loading = true;
                const {data} = await ProjectsRepository.getStats(this.currentProject);
                this.progress = (data.project_article_finalized / data.project_day_count) * 100;
                this.stats = data;
            },
            async fetchArticleStats() {
                this.loading = true;
                const {data} = await ArticlesRepository.getStats(this.currentProject);
                this.articleStats = data;
                this.loading = false;
            },
        },
        computed: {
            currentProject() {
                return this.$store.getters.currentProject;
            },
            topics() {
                let self = this;
                return self.stats.topics.sort((a, b) => (a.topic_count < b.topic_count) ? 1 : -1)
            },
        },
    }
</script>
