<template>
  <v-app id="inspire">
    <MenuSetDefault />

    <v-main>
      <div class="text-left">
        <v-sheet color="accent" class="py-5 px-5">
          <h3>Beitragsplanung</h3>
        </v-sheet>
      </div>
      <v-card class="elevation-0 px-5">
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" fixed-tabs>
            <v-tab v-for="i in 12" :key="i" class="text-h6">
              {{ months[i - 1].name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="i in 12" :key="i">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Datum</th>
                      <th>Wochentag</th>
                      <th>Arbeitstitel</th>
                      <th>Autor</th>
                      <th>Lernvers</th>
                      <th>Lernversposition</th>
                      <th>Erstellt</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="j in getAllDaysInMonth(
                        $store.state.currentProjectMeta.year,
                        i - 1
                      )"
                      :key="j.toString()"
                    >
                      <td>
                        {{ j.toLocaleDateString("en-CH") }}
                      </td>
                      <td>
                        {{ weekdays[j.getDay()] }}
                      </td>
                      <td>
                        <template v-for="article in articles">
                          <template v-for="topic in contentTopics">
                            {{
                              new Date(
                                article.assigned_date
                              ).toLocaleDateString() ==
                                j.toLocaleDateString() &&
                              topic.id == article.content_topic
                                ? topic.name
                                : ""
                            }}
                          </template>
                        </template>
                      </td>
                      <td>
                        <template v-for="article in articles">
                          <template v-for="author in authors">
                            {{
                              new Date(
                                article.assigned_date
                              ).toLocaleDateString() ==
                                j.toLocaleDateString() &&
                              author.id == article.writer
                                ? author.full_name
                                : ""
                            }}
                          </template>
                        </template>
                      </td>
                      <td>
                        <template v-for="article in articles">
                          {{
                            new Date(
                              article.assigned_date
                            ).toLocaleDateString() == j.toLocaleDateString()
                              ? article.learning_verse
                              : ""
                          }}
                        </template>
                      </td>
                      <td>
                        <template v-for="article in articles">
                          {{
                            new Date(
                              article.assigned_date
                            ).toLocaleDateString() == j.toLocaleDateString()
                              ? article.learning_verse_ref
                              : ""
                          }}
                        </template>
                      </td>
                      <td>
                        <template v-for="article in articles">
                          <template
                            v-if="
                              new Date(
                                article.assigned_date
                              ).toLocaleDateString() == j.toLocaleDateString()
                            "
                          >
                            <v-icon
                              class="mx-4"
                              :color="`${article.used ? 'green' : 'grey'}`"
                              :key="article.used"
                            >
                              {{
                                article.used
                                  ? "mdi-check-circle-outline"
                                  : "mdi-close-circle-outline"
                              }}
                            </v-icon>
                          </template>
                        </template>
                      </td>
                      <td class="text-right">
                        <template v-for="article in articles">
                          <template
                            v-if="
                              new Date(
                                article.assigned_date
                              ).toLocaleDateString() ==
                                j.toLocaleDateString() && article.reused_article
                            "
                          >
                            <span :key="article.id" v-if="!article.used">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    :key="article.id"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-alert-circle-outline
                                  </v-icon>
                                </template>
                                <span>
                                  Dieser Plan enthält ein Archivartikel. <br />
                                  Dieser wird genutzt um einen neuen Artikel zu
                                  erstellen
                                </span>
                              </v-tooltip>
                              <v-btn
                                class="mx-2"
                                @click="createFromArchive(article.id)"
                              >
                                Artikel erstellen
                              </v-btn>
                              <v-btn
                                class="mr-2"
                                @click="previewArticle(article.reused_article)"
                              >
                                <v-icon>mdi-text-box-search-outline</v-icon>
                              </v-btn>
                            </span>
                            <v-tooltip v-else bottom :key="article.id">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-2" v-bind="attrs" v-on="on">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </template>
                              <span>
                                Der Artikel zu diesem Plan wurde mithilfe eines
                                Archivartikels erstellt
                              </span>
                            </v-tooltip>
                          </template>
                        </template>
                        <v-btn @click="openDialog(j)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-dialog v-model="dialog" width="700" @click:outside="cancelDialog">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Beitragsplanung für
              {{ weekdays[new Date(selectedPlan.assigned_date).getDay()] }}
              den
              {{ new Date(selectedPlan.assigned_date).toLocaleDateString() }}
            </v-card-title>

            <v-card-text>
              <div class="text-right">
                <v-btn
                  v-if="selectedPlan.reused_article"
                  class="mt-2"
                  @click="resetSelection(true)"
                  color="red"
                >
                  Archivartikel entfernen
                </v-btn>
                <v-btn v-else class="mt-2" @click="openArchive">
                  Aus dem Archiv füllen
                </v-btn>
              </div>
              <v-autocomplete
                label="Autor *"
                item-text="full_name"
                item-value="id"
                :items="authors"
                v-model="selectedPlan.writer"
                :disabled="selectedPlan.reused_article ? true : false"
                :rules="[(v) => !!v || 'Autor ist ein Pflichtfeld']"
              />
              <v-combobox
                label="Arbeitstitel"
                v-model="contentTopic"
                :items="contentTopics"
                :disabled="selectedPlan.reused_article ? true : false"
                item-text="name"
                filled
                small-chips
                class="mb-4"
                hide-details
              />
              <TopicSelection
                v-model="topic"
                :disabled="disableTopicSelection ? true : false"
              />
              <v-textarea
                label="Lernvers"
                v-model="selectedPlan.learning_verse"
                :disabled="selectedPlan.reused_article ? true : false"
              />
              <v-text-field
                label="Lernvers Bibelstelle"
                v-model="selectedPlan.learning_verse_ref"
                :disabled="selectedPlan.reused_article ? true : false"
              />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn color="primary" text @click="cancelDialog">
                abbrechen
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="saveAndClose">
                speichern
              </v-btn>
              <v-btn color="primary" @click="saveAndNext">
                speichern und nächsten bearbeiten
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-dialog
        v-model="archiveDialog"
        width="300"
        @click:outside="cancelArchiveDialog"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Beitrag aus dem Archiv nutzen
          </v-card-title>
          <v-card-text class="text-center">
            Geben Sie die ID des Beitrags ein, den sie nutzen möchten:
            <v-text-field v-model.number="archiveID" />
            <v-btn @click="getArchiveArticle">Beitrag suchen</v-btn>
            <div v-if="Object.keys(archiveArticle).length > 0">
              {{ archiveArticle.title }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="previewItem()" v-bind="attrs" v-on="on">
                    <v-icon>mdi-text-box-search-outline</v-icon>
                  </v-btn>
                </template>
                <span>Vorschau</span>
              </v-tooltip>
              <v-btn @click="useArchiveArticle">
                Diesen Beitrag nutzen
              </v-btn>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="cancelArchiveDialog">
              abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <DialogPreviewFull
        :dialog="previewDialog"
        :article_id="archiveID"
        @close="closePreview"
      />
    </v-main>
  </v-app>
</template>

<script>
import MenuSetDefault from "@/components/layout/MenuSetDefault"
import DialogPreviewFull from "@/components/articles/DialogPreviewFull"
import TopicSelection from "@/components/articles/TopicSelection"

import projectMixin from "@/mixins/projectMixin"

import { RepositoryFactory } from "@/repositories/RepositoryFactory"
const ArticlesRepository = RepositoryFactory.get("articles")
const TopicsRepository = RepositoryFactory.get("topics")
const UsersRepository = RepositoryFactory.get("users")
const PlannedArticlesRepository = RepositoryFactory.get("plannedArticles")

export default {
  name: "Beitragsplanung",
  mixins: [projectMixin],
  components: {
    MenuSetDefault,
    DialogPreviewFull,
    TopicSelection,
  },
  data() {
    return {
      dialog: false,
      archiveDialog: false,
      previewDialog: false,
      year: null,
      valid: false,
      weekdays: [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
      ],
      months: [
        { name: "Januar", length: 31 },
        { name: "Februar", length: 28 },
        { name: "März", length: 31 },
        { name: "April", length: 30 },
        { name: "Mai", length: 31 },
        { name: "Juni", length: 30 },
        { name: "Juli", length: 31 },
        { name: "August", length: 31 },
        { name: "September", length: 30 },
        { name: "Oktober", length: 31 },
        { name: "November", length: 30 },
        { name: "Dezember", length: 31 },
      ],
      articles: [],
      selectedPlan: {},
      archiveID: 0,
      archiveArticle: {},
      contentTopics: [],
      contentTopic: null,
      topics: {},
      topic: {},
      subTopics: {},
      authors: [],
      tab: 0,
      disableTopicSelection: false,
    }
  },
  async created() {
    const self = this

    await this.fetchTopics()
    await this.fetchArticlePlannings()

    await UsersRepository.get().then(function(response) {
      self.authors = response.data
        //use group name instead of id
        .filter((item) => item.groups_data.find((x) => x.name === "Author"))
        .map((item) => {
          return {
            id: item.id,
            full_name: item.full_name,
            // ...item,
          }
        })
    })
  },
  methods: {
    async fetchTopics() {
      const { data: topicsData } = await TopicsRepository.get()
      this.topics = topicsData
      const { data: subTopicsData } = await TopicsRepository.getSubTopics()
      this.subTopics = subTopicsData
      const {
        data: contentTopicsData,
      } = await TopicsRepository.getContentTopics()
      this.contentTopics = contentTopicsData
    },
    async fetchArticlePlannings() {
      const {
        data: plannedArticlesData,
      } = await PlannedArticlesRepository.get()
      this.articles = plannedArticlesData
    },
    openDialog(selectedDate) {
      selectedDate.setDate(selectedDate.getDate() + 1)
      for (let article in this.articles) {
        article = this.articles[article]
        if (
          article.assigned_date ==
          this.$luxon(selectedDate.toISOString().slice(0, 10), "yyyy-MM-dd")
        ) {
          this.selectedPlan = article
          this.contentTopic = article.content_topic_data

          break
        } else {
          this.selectedPlan = {}
        }
      }
      if (Object.keys(this.selectedPlan).length == 0) {
        this.selectedPlan = {
          assigned_date: this.$luxon(
            selectedDate.toISOString().slice(0, 10),
            "yyyy-MM-dd"
          ),
          writer: this.authors[0].id,
          content_topic: 1,
        }
      }
      this.dialog = true
      this.$refs.form.resetValidation()
    },
    cancelDialog() {
      this.dialog = false
      this.resetSelection(false)
      this.topic = {}
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },
    async savePlanning() {
      let newContentTopic = null
      if (
        typeof this.contentTopic === "string" ||
        this.contentTopic instanceof String
      ) {
        let topicIdentifiers = this.topic.identifier.split("-")
        const {
          data: contentTopicData,
        } = await TopicsRepository.addContentTopic(
          this.contentTopic,
          topicIdentifiers[0],
          topicIdentifiers[1]
        )
        newContentTopic = contentTopicData
      }

      if (this.selectedPlan.id) {
        await PlannedArticlesRepository.update(
          this.selectedPlan.id,
          this.selectedPlan.assigned_date,
          this.$store.getters.currentProject,
          this.selectedPlan.writer,
          newContentTopic ? newContentTopic.id : this.contentTopic.id,
          this.selectedPlan.reused_article,
          this.selectedPlan.learning_verse,
          this.selectedPlan.learning_verse_ref
        )
      } else {
        await PlannedArticlesRepository.add(
          this.selectedPlan.assigned_date,
          this.$store.getters.currentProject,
          this.selectedPlan.writer,
          newContentTopic ? newContentTopic.id : this.contentTopic.id,
          this.selectedPlan.reused_article,
          this.selectedPlan.learning_verse,
          this.selectedPlan.learning_verse_ref
        )
      }

      await this.fetchTopics()
      await this.fetchArticlePlannings()
    },
    async saveAndClose() {
      await this.savePlanning()

      this.cancelDialog()
    },
    async saveAndNext() {
      await this.savePlanning()
      var nextDate = new Date(this.selectedPlan.assigned_date)
      nextDate.setDate(nextDate.getDate() + 1)
      if (this.$refs.form.validate()) {
        for (let article in this.articles) {
          article = this.articles[article]
          if (
            article.assigned_date ==
            this.$luxon(nextDate.toISOString().slice(0, 10), "yyyy-MM-dd")
          ) {
            this.selectedPlan = article
            this.contentTopic = article.content_topic_data

            break
          } else {
            // this.resetSelection(false)
            this.selectedPlan = {}
          }
        }
        if (Object.keys(this.selectedPlan).length == 0) {
          this.selectedPlan = {
            assigned_date: this.$luxon(
              nextDate.toISOString().slice(0, 10),
              "yyyy-MM-dd"
            ),
            writer: this.authors[0].id,
            content_topic: 1,
          }
          this.contentTopic = null
          this.topic = null
        }
        this.$refs.form.resetValidation()
      }
    },
    getAllDaysInMonth(year, month) {
      const date = new Date(year, month, 1)

      const dates = []

      while (date.getMonth() === month) {
        dates.push(new Date(date))
        date.setDate(date.getDate() + 1)
      }

      return dates
    },
    openArchive() {
      this.dialog = false
      this.archiveDialog = true
    },
    cancelArchiveDialog() {
      this.archiveID = 0
      this.archiveArticle = {}
      this.archiveDialog = false
      this.dialog = true
    },
    async getArchiveArticle() {
      const { data } = await ArticlesRepository.getArticle(this.archiveID)
      this.archiveArticle = data
    },
    useArchiveArticle() {
      this.archiveDialog = false
      this.selectedPlan.writer = this.archiveArticle.writer
      this.selectedPlan.reused_article = this.archiveArticle.id
      this.selectedPlan.learning_verse = this.archiveArticle.learning_verse
      this.selectedPlan.learning_verse_ref = this.archiveArticle.learning_verse_ref
      this.contentTopic = this.archiveArticle.content_topic_data
      this.dialog = true
    },
    async createFromArchive(id) {
      await PlannedArticlesRepository.createFromArchive(id)

      const {
        data: plannedArticlesData,
      } = await PlannedArticlesRepository.get()
      this.articles = plannedArticlesData
    },
    resetSelection(keepID) {
      this.selectedPlan = {
        id: keepID ? this.selectedPlan.id : undefined,
        reused_article: keepID ? null : this.selectedPlan.reused_article,
        assigned_date: this.selectedPlan.assigned_date,
        writer: this.authors[0].id,
        content_topic: 1,
      }
    },
    previewArticle(articleID) {
      this.archiveID = articleID
      this.previewDialog = true
    },
    previewItem() {
      this.previewDialog = true
    },
    closePreview() {
      this.previewDialog = false
    },
    setSelectedTopic(contentTopic) {
      let topicIdentifier = contentTopic.topic

      if (contentTopic.sub_topic) {
        topicIdentifier = topicIdentifier + "-" + contentTopic.sub_topic
      }

      if (
        this.topics.filter((topic) => topic.identifier == topicIdentifier)
          .length > 0
      ) {
        this.topic = this.topics.filter(
          (topic) => topic.identifier == topicIdentifier
        )[0]
      } else {
        this.topic = this.subTopics.filter(
          (subTopic) => subTopic.identifier == topicIdentifier
        )[0]
      }
    },
  },
  watch: {
    contentTopic(newValue) {
      if (newValue != null && typeof newValue === "object") {
        this.setSelectedTopic(newValue)
        this.disableTopicSelection = true
      } else {
        this.disableTopicSelection = false
      }
    },
  },
}
</script>
