<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">

                    <v-toolbar-title>Themenbereiche</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">
                            <v-btn v-bind="tooltipAttrs"
                                   v-on="tooltip"
                                   @click="editItem()"
                                   absolute dark fab bottom right color="primary">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Themenbereich erstellen</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-title>
                    <v-text-field style="max-width: 250px"
                                  v-model="search"
                                  append-icon="mdi-magnify"
                                  label="Suchen"
                                  single-line
                                  hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                        :loading="loading" loading-text="Daten werden geladen... Bitte warten"
                        :headers="headers"
                        :items="topics"
                        :items-per-page="10"
                        :search="search"
                        show-expand
                        :expanded="topics"
                >
                    <template v-slot:top>
                        <DialogTopic
                                :dialog="dialog"
                                :editedItem="editedItem"
                                :editedItemParent="editedItemParent"
                                :formTitle="formTitle"
                                :editedIndex="editedIndex"
                                @saved="saved"
                                @close="close"
                        />
                    </template>
                    <template v-slot:item.img="{ item }">
                        <v-img
                        v-if="item.img_b64 != null"
                        contain
                        max-height="50"
                        max-width="100"
                        :src="item.img_b64"
                        ></v-img>
                    </template>
                    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }" >
                        <v-chip>
                            {{ item.sub_topics.length }}
                        </v-chip>
                        <template v-if="item.sub_topics.length > 0">
                            <v-btn @click="expand(true)" v-if="!isExpanded" icon>
                                <v-icon>
                                    mdi-chevron-down
                                </v-icon>
                            </v-btn>
                            <v-btn @click="expand(false)" v-if="isExpanded" icon>
                                <v-icon>
                                    mdi-chevron-up
                                </v-icon>
                            </v-btn>
                        </template>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" v-if="item.sub_topics.length > 0"  style="padding: 0px;">
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="subItem in item.sub_topics" :key="subItem.id">
                                            <td width="100"></td>
                                            <td>
                                                &rdsh; 
                                                {{ subItem.name }}
                                            </td>
                                            <td class="text-right">
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon
                                                            plain
                                                        >
                                                            <v-icon>mdi-dots-vertical</v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <v-list dense>
                                                        <v-list-item @click="editItem(subItem, item)">
                                                            <v-list-item-icon>
                                                                <v-icon>                                
                                                                    mdi-square-edit-outline
                                                                </v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Unterthema bearbeiten</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item @click="showContentTopics(subItem)">
                                                            <v-list-item-icon>
                                                                <v-icon>                                
                                                                    mdi-list-box
                                                                </v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Arbeitstitel anzeigen</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>

                                                        <!-- <v-list-item @click="deleteItem(item)">
                                                            <v-list-item-icon>
                                                                <v-icon>                                
                                                                    mdi-delete-outline
                                                                </v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Projekt Löschen</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item> -->
                                                    </v-list>
                                                </v-menu>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </td>
                    </template>
                    <template v-slot:item.id="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    plain
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item @click="editItem(null, item)">
                                    <v-list-item-icon>
                                        <v-icon>                                
                                            mdi-plus
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Unterthema erstellen</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item @click="showContentTopics(item)">
                                    <v-list-item-icon>
                                        <v-icon>                                
                                            mdi-list-box
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Arbeitstitel anzeigen</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider />

                                <v-list-item @click="editItem(item)">
                                    <v-list-item-icon>
                                        <v-icon>                                
                                            mdi-square-edit-outline
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Thema bearbeiten</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <!-- <v-list-item @click="deleteItem(item)">
                                    <v-list-item-icon>
                                        <v-icon>                                
                                            mdi-delete-outline
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Projekt Löschen</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item> -->
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-card>

            <DialogContentTopics
                v-if="showContentTopicsDialog"
                :dialog="showContentTopicsDialog"
                :topic="showContentTopicsOf"
                @close="showContentTopicsDialog = false"
            />
        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogTopic from "@/components/settings/DialogTopic";
    import DialogContentTopics from '@/components/settings/DialogContentTopics';

    const TopicsRepository = RepositoryFactory.get('topics');

    export default {
        name: "Topics",
        components: {
            MenuSetDefault,
            DialogTopic,
            DialogContentTopics
        },
        props: {
            source: String,
        },
        data() {
            return {
                isShowForm: false,
                name: '',
                search: '',
                topics: [],
                loading: true,
                headers: [
                    {text: 'Symbol', value: 'img', width: 100},
                    {text: 'Themenbereich', value: 'name'},
                    {text: 'Unterthemen', value: 'data-table-expand', align: 'start'},
                    {text: 'Aktionen', value: 'id', align: 'right'},
                ],
                dialog: false,
                editedIndex: -1,
                editedItem: {
                    id: null,
                    name: '',
                },
                editedItemParent: {
                    id: null,
                    name: '',
                },
                defaultItem: {
                    id: null,
                    name: '',
                    img_b64: null,
                    sub_topics: []
                },
                showContentTopicsDialog: false,
                showContentTopicsOf: {}
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await TopicsRepository.get();
                this.topics = data;
                this.loading = false;
            },
            // createTopic: function () {
            //     TopicsRepository.create(this.name)
            //         .then(response => {
            //             this.topics.push(response.data);
            //             this.name = '';
            //             this.isShowForm = false;
            //         })
            //         .catch(err => console.log(err))
            // },
            showContentTopics(topic) {
                this.showContentTopicsOf = topic
                this.showContentTopicsDialog = true
            },
            editItem (item, parent = undefined) {
                if (item === undefined) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    if (parent) {
                        this.editedItemParent = Object.assign({}, parent)
                    }
                } else {
                    this.editedIndex = this.topics.indexOf(item);
                    this.editedItem = Object.assign({}, item)
                    if (parent) {
                        this.editedItemParent = Object.assign({}, parent)
                    }
                }
                this.dialog = true
            },
            saved () {
                this.fetch()
                this.close()
            },
            deleteItem (item) {
                this.editedIndex = this.topics.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true
            },
            deleteItemConfirm (editedIndex) {
                this.topics.splice(editedIndex, 1);
                this.closeDelete()
            },
            close () {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedItemParent = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
            closeDelete () {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
        },
        computed: {
            formTitle () {
                if (this.editedItemParent.name) {
                    return this.editedIndex === -1 ? 'Neuen Unterthemenbereich anlegen' : 'Unterthemenbereich bearbeiten'
                } else {
                    return this.editedIndex === -1 ? 'Neuen Themenbereich anlegen' : 'Themenbereich bearbeiten'
                }
            },
        },
        created() {
            this.fetch();
        },
    }
</script>
