const strings = {
    lim: {
        TITLE: "Leben ist mehr",
        ABBREVIATION: "LIM",
        EMAIL: "info@lebenistmehr.de",
        APILINK: "https://api.lim.clv.de",
        REPOLINK: "https://git.einz.de/clv/clv-lim-redaktion",
        EDITORIALEMAIL: "redaktion@lim.clv.de"
    },
    hs: {
        TITLE: "Die Helle Straße",
        ABBREVIATION: "DHS",
        EMAIL: "info@diehellestrasse.de",
        APILINK: "https://api.dhs.de",
        REPOLINK: "https://git.einz.de/clv/clv-lim-redaktion",
        EDITORIALEMAIL: "redaktion@dhs.de"
    },
}

const projectData = {
    lim: {
        articleTextLength: 1700,
        verseLength: 180,
        articleQuestionField: true,
        articleHintField: true,
        hasArchive: true,
        hasFreeArticleSubmission: true
    },
    hs: {
        yearOrder: [3, 0, 1, 2],
        articleTextLength: 930,
        verseLength: 110,
        hasReadingPlan: true,
        hasArticlePlan: true,
        hasHistoricalArticles: true,
        hasContentTopics: true
    }
}
// yearOrder: [0, 1, 2, 3],
// articleTextLength: 1000,
// articleWorktitleField: false,
// hasReadingPlan:false,
// hasArticlePlan:false,
// articleQuestionField: false,
// articleHintField: false,
// hasArchive: false
// hasFreeArticleSubmission: false


export default {
    data() {
        let instanceData = projectData[process.env.VUE_APP_PROJECT_ID]

        return {
            instanceData
        }
    },
    methods: {
        __getString(name) {
            return strings[process.env.VUE_APP_PROJECT_ID][name] || ''
        }
    },
}