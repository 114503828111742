<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">

                    <v-toolbar-title>Gemeinden</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">
                            <v-btn v-bind="tooltipAttrs"
                                   v-on="tooltip"
                                   @click="editItem()"
                                   absolute dark fab bottom right color="primary">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Gemeinde erstellen</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-title>
                    <v-text-field style="max-width: 250px"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Suchen"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table
                        :loading="loading"
                        loading-text="Daten werden geladen... Bitte warten"
                        :headers="headers"
                        :items="churches"
                        :items-per-page="15"
                        :search="search"
                >
                    <template v-slot:top>
                        <DialogChurch
                            :dialog="dialog"
                            :editedItem="editedItem"
                            :formTitle="formTitle"
                            :editedIndex="editedIndex"
                            @saved="saved"
                            @close="close"
                        />
                        <DialogChurchDelete
                            :dialogDelete="dialogDelete"
                            :editedIndex="editedIndex"
                            dialogTitle="Gemeinde wirklich entfernen?"
                            @deleteItemConfirm="deleteItemConfirm"
                            @closeDelete="closeDelete"
                        />
                    </template>
                    <template v-slot:item.id="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    plain
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item @click="editItem(item)">
                                    <v-list-item-icon>
                                        <v-icon>                                
                                            mdi-square-edit-outline
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Gemeinde bearbeiten</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <!-- <v-list-item @click="deleteItem(item)">
                                    <v-list-item-icon>
                                        <v-icon>                                
                                            mdi-delete-outline
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Projekt Löschen</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item> -->
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-card>

        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogChurchDelete from "@/components/settings/DialogChurchDelete";
    import DialogChurch from "@/components/settings/DialogChurch";

    const ChurchesRepository = RepositoryFactory.get('churches');

    export default {
        name: "Churches",
        components: {
            MenuSetDefault,
            DialogChurchDelete,
            DialogChurch,
        },
        data() {
            return {
                isShowForm: false,
                name: '',
                name_full: '',
                search: '',
                churches: [],
                loading: true,
                headers: [
                    {text: 'Name', value: 'name'},
                    {text: 'Voller Name', value: 'name_full'},
                    {text: 'Aktionen', value: 'id', align: 'right'},
                ],
                dialog: false,
                dialogDelete: false,
                editedIndex: -1,
                editedItem: {
                    id: null,
                    name: '',
                    name_full: '',
                    website: '',
                },
                defaultItem: {
                    id: null,
                    name: '',
                    website: '',
                },
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await ChurchesRepository.get();
                this.churches = data;
                this.loading = false;
            },
            editItem (item) {
                if (item === undefined) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                } else {
                    this.editedIndex = this.churches.indexOf(item);
                    this.editedItem = Object.assign({}, item)
                }
                this.dialog = true
            },
            saved (editedItem) {
                if (this.editedIndex > -1) {
                    Object.assign(this.churches[this.editedIndex], editedItem)
                } else {
                    this.churches.push(editedItem);
                }
                this.close()
            },
            deleteItem (item) {
                this.editedIndex = this.churches.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true
            },
            deleteItemConfirm (editedIndex) {
                this.churches.splice(editedIndex, 1);
                this.closeDelete()
            },
            close () {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
            closeDelete () {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Neue Gemeinde anlegen' : 'Gemeinde bearbeiten'
            },
        },
        created() {
            this.fetch();
        },
    }
</script>
