var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('MenuSetDefault'),_c('v-main',[_c('v-card',{staticClass:"elevation-0"},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":"","color":"accent"}},[_c('v-toolbar-title',[_vm._v("Themenbereiche")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","color":"primary"},on:{"click":function($event){return _vm.editItem()}}},'v-btn',tooltipAttrs,false),tooltip),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Themenbereich erstellen")])])],1),_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Daten werden geladen... Bitte warten","headers":_vm.headers,"items":_vm.topics,"items-per-page":10,"search":_vm.search,"show-expand":"","expanded":_vm.topics},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('DialogTopic',{attrs:{"dialog":_vm.dialog,"editedItem":_vm.editedItem,"editedItemParent":_vm.editedItemParent,"formTitle":_vm.formTitle,"editedIndex":_vm.editedIndex},on:{"saved":_vm.saved,"close":_vm.close}})]},proxy:true},{key:"item.img",fn:function(ref){
var item = ref.item;
return [(item.img_b64 != null)?_c('v-img',{attrs:{"contain":"","max-height":"50","max-width":"100","src":item.img_b64}}):_vm._e()]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.sub_topics.length)+" ")]),(item.sub_topics.length > 0)?[(!isExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1):_vm._e(),(isExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v(" mdi-chevron-up ")])],1):_vm._e()]:_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.sub_topics.length > 0)?_c('td',{staticStyle:{"padding":"0px"},attrs:{"colspan":headers.length}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item.sub_topics),function(subItem){return _c('tr',{key:subItem.id},[_c('td',{attrs:{"width":"100"}}),_c('td',[_vm._v(" ↳ "+_vm._s(subItem.name)+" ")]),_c('td',{staticClass:"text-right"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(subItem, item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Unterthema bearbeiten")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.showContentTopics(subItem)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-list-box ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Arbeitstitel anzeigen")])],1)],1)],1)],1)],1)])}),0)]},proxy:true}],null,true)})],1):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(null, item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-plus ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Unterthema erstellen")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.showContentTopics(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-list-box ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Arbeitstitel anzeigen")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Thema bearbeiten")])],1)],1)],1)],1)]}}])})],1),(_vm.showContentTopicsDialog)?_c('DialogContentTopics',{attrs:{"dialog":_vm.showContentTopicsDialog,"topic":_vm.showContentTopicsOf},on:{"close":function($event){_vm.showContentTopicsDialog = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }