var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('MenuSetDefault'),_c('v-main',[_c('v-card',{staticClass:"elevation-0",attrs:{"flat":"","tile":""}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":"","color":"accent"}},[_c('v-toolbar-title',[_vm._v("Nicht zugeordnete Beiträge")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:(_vm.instanceData.hasFreeArticleSubmission ? 'right: 90px;' : ''),attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","fixed":"","color":_vm.showSelect && _vm.selected.length > 0 ? 'primary' :'secondary'},on:{"click":function($event){!_vm.showSelect || _vm.selected.length === 0 ? _vm.showSelect = !_vm.showSelect : _vm.archiveItems(_vm.selected.map(function (a) { return a.id; }))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.showSelect && _vm.selected.length === 0 ? 'mdi-close' : 'mdi-archive-arrow-down-outline'))])],1)]}}])},[_c('span',[_vm._v("archivieren")])]),(_vm.instanceData.hasFreeArticleSubmission)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.newItem}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,4170415070)},[_c('span',[_vm._v("Beitrag erstellen")])]),_c('DialogArticleNew',{attrs:{"dialog":_vm.newArticleDialog},on:{"close":_vm.close,"saved":_vm.saved}})]:_vm._e()],2),_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.tableSettings.search),callback:function ($$v) {_vm.$set(_vm.tableSettings, "search", $$v)},expression:"tableSettings.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.articles,"search":_vm.tableSettings.search,"loading":_vm.loading,"loading-text":"Daten werden geladen... Bitte warten","footer-props":{'items-per-page-options': [10, 25, 50, -1]},"options":_vm.tableSettings,"showSelect":_vm.showSelect,"custom-sort":_vm.customSort},on:{"update:options":_vm.updateTableSettings},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('DialogPreviewFull',{attrs:{"dialog":_vm.preview_dialog,"article_id":_vm.preview_id},on:{"close":_vm.close}}),_c('DialogArticleDelete',{attrs:{"dialogDelete":_vm.dialogDelete,"article":_vm.articleToDelete,"index":_vm.indexToDelete,"dialogTitle":"Beitrag wirklich entfernen?"},on:{"deleteArticleConfirm":_vm.deleteArticleConfirm,"closeDelete":_vm.close}})]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/articles/' + item.id}},[_vm._v(_vm._s(item.title))])]}},{key:"item.topic",fn:function(ref){
var item = ref.item;
return [(_vm.instanceData.hasReadingPlan)?[_c('div',{staticStyle:{"line-height":"16px"}},[_c('strong',{staticStyle:{"font-size":"1rem"}},[_vm._v(_vm._s(item.content_topic_name))]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(item.topic_name)+" "+_vm._s(item.sub_topic != null ? " > " + item.sub_topic_name : "")+" ")])]:[_vm._v(" "+_vm._s(item.topic_name)+" "+_vm._s(item.sub_topic != null ? " > " + item.sub_topic_name : "")+" ")]]}},{key:"item.event_day_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.event_day_name)+" "),(item.event_day_topic_name && item.event_day_name)?_c('span',[_vm._v(" | ")]):_vm._e(),_vm._v(" "+_vm._s(item.event_day_topic_name)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("luxon")(item.created_at))+" ")])]}},{key:"item.latestActivityDate",fn:function(ref){
var item = ref.item;
return [(item.latestActivity.length > 0)?_c('small',[_vm._v(" "+_vm._s(item.latestActivity[0].user.match(/[A-Z]/g).join(''))+" "+_vm._s(_vm._f("luxon")(item.latestActivity[0].datetime,"relative"))+" ")]):_vm._e()]}},{key:"item.votd_reference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseBibleRef(item.votd_reference, item.votd_reference_overwrite))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.previewItem(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-eye-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Vorschau")])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":'/articles/' + item.id}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("bearbeiten")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.archiveItems([item.id])}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-archive-arrow-down-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("archivieren")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("löschen")])],1)],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }