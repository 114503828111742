<template>
  <v-dialog v-model="dialog" max-width="500px" @click:outside="close" @keydown.esc="close">
    <v-card :loading="loading">
      <template slot="progress">
        <v-progress-linear background-color="primary" color="white" indeterminate bottom></v-progress-linear>
      </template>

      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-form
          ref="form"
          v-model="valid"
          lazy-validation>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="editedItem.name"
                    label="Name*"
                    :rules="[v => !!v || 'Name ist ein Pflichtfeld']"
                    required
                    filled
                />
              </v-col>

              <v-col cols="12" v-if="editedItem.img_b64 != null">
                <v-img
                    contain
                    max-height="150"
                    max-width="450"
                    :src="editedItem.img_b64"
                ></v-img>
              </v-col>

              <v-col cols="12">
                <v-file-input
                    ref="fileUpload"
                    v-model="img_upload"
                    accept="image/png, image/jpeg"
                    :placeholder="editedItem.img_b64 ? 'Symbol für Thema ändern' : 'Symbol für Thema hinzufügen'"
                    prepend-icon="mdi-image"
                    :label="editedItem.img_b64 ? 'Bild ändern' : 'Bild hinzufügen'"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="close">Schließen</v-btn>
          <v-btn color="primary" elevation="0" @click="save">Speichern</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {RepositoryFactory} from "@/repositories/RepositoryFactory";

const TopicsRepository = RepositoryFactory.get('topics');

export default {
  name: "DialogTopic",
  props: {
    dialog: Boolean,
    editedItem: Object,
    editedItemParent: Object,
    editedIndex: Number,
    formTitle: String,
  },
  data() {
    return {
      loading: false,
      img_upload: [],
      valid: true,
      subIndex: -1
    }
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if(this.editedItemParent.name) {
          if (this.subIndex === -1) {
            TopicsRepository.addSubTopic(this.editedItem.name, this.editedItem.img_b64, this.editedItemParent.id)
                .then(response => {
                  this.saved(response.data);
                  this.loading = false;
                  this.$store.dispatch('setMessage', 'Der Unterthemenbereich "' + this.editedItem.name + '" wurde erfolgreich gespeichert.');
                  // this.reset();
                })
                .catch(err => {
                      this.close();
                      this.loading = false;
                      console.log(err);
                      this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                    }
                );
          } else {
            TopicsRepository.updateSubTopic(this.editedItem.id, this.editedItem.name, this.editedItem.img_b64, this.editedItemParent.id)
                .then(response => {
                  this.saved(response.data);
                  this.loading = false;
                  this.$store.dispatch('setMessage', 'Der Unterthemenbereich "' + this.editedItem.name + '" wurde erfolgreich geändert.');
                  // this.reset();
                })
                .catch(err => {
                      this.close();
                      this.loading = false;
                      console.log(err);
                      this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                    }
                );
          }
        } else {
          if (this.editedIndex === -1) {
            TopicsRepository.add(this.editedItem.name, this.editedItem.img_b64)
                .then(response => {
                  this.saved(response.data);
                  this.loading = false;
                  this.$store.dispatch('setMessage', 'Der Themenbereich "' + this.editedItem.name + '" wurde erfolgreich gespeichert.');
                  // this.reset();
                })
                .catch(err => {
                      this.close();
                      this.loading = false;
                      console.log(err);
                      this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                    }
                );
          } else {
            TopicsRepository.update(this.editedItem.id, this.editedItem.name, this.editedItem.img_b64)
                .then(response => {
                  this.saved(response.data);
                  this.loading = false;
                  this.$store.dispatch('setMessage', 'Der Themenbereich "' + this.editedItem.name + '" wurde erfolgreich geändert.');
                  // this.reset();
                })
                .catch(err => {
                      this.close();
                      this.loading = false;
                      console.log(err);
                      this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                    }
                );
          }
        }

      }
    },
    saved(item) {
      this.$emit('saved', item);
    },
    close() {
      this.reset();
      this.$emit('close', this.editedItem);
    },
    reset() {
      this.$refs.form.reset();
      this.img_upload = [];
      // this.$refs.fileUpload.value = null;
    },
  },
  watch: {
    img_upload(val) {
      let self = this;
      if (val.length > 0) {
        let reader = new FileReader();
        reader.onloadend = function () {
          self.editedItem.img_b64 = reader.result;
        };
        reader.readAsDataURL(val);
      } else {
        self.editedItem.img_b64 = null;
      }

    },
    dialog() {
      if(
        this.editedItemParent.sub_topics?.length > 0
      ) {
        this.subIndex = this.editedItemParent.sub_topics.findIndex((element) => element.id == this.editedItem.id)
      }
    }

  }
}
</script>

<style scoped>

</style>
