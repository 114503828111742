<template>
  <v-card class="print-card" elevation="0">
    <v-card-text>
      <component
        v-bind:is="previewComponent"
        :article="article"
        :show_ids="show_ids"
        :show_lines="show_lines"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ArticlePrintLayoutLim from "./printLayouts/lim.vue"
import ArticlePrintLayoutHs from "./printLayouts/hs.vue"

export default {
  name: "ArticlePreviewFull",
  components: { ArticlePrintLayoutLim, ArticlePrintLayoutHs },
  props: {
    article: Object,
    show_lines: Boolean,
    show_ids: Boolean,
  },
  data() {
    return {
      previewComponent: "ArticlePrintLayoutLim",
    }
  },
  created() {
    this.previewComponent =
      "ArticlePrintLayout" +
      this.capitalizeFirstLetter(process.env.VUE_APP_PROJECT_ID)
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
}
</script>
