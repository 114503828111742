<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card :loading="loading">
      <template slot="progress">
        <v-progress-linear
          background-color="primary"
          color="white"
          indeterminate
          bottom
        />
      </template>

      <v-card-title class="mb-2">
        <v-row>
          <v-col cols="7" style="padding: 0 12px;">
            <span class="headline">Kalenderblattvorschau</span>
          </v-col>

          <v-col cols="5" style="padding: 0 12px;">
            <v-switch
              v-model="show_lines"
              :label="`Zeilennummern`"
              dense
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <component
          v-bind:is="previewComponent"
          :article="article"
          :show_ids="false"
          :show_lines="show_lines"
          :loading="loading"
        />
        <!-- <ArticlePrintLayoutLim
            :article="article"
            :show_ids="false"
            :show_lines="show_lines"
            :loading="loading"
        /> -->
      </v-card-text>

      <v-card-actions v-if="edit_btn">
        <v-spacer />
        <v-btn :to="`/articles/${article.id}`" color="primary" elevation="0">
          Bearbeiten
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory"
import ArticlePrintLayoutLim from "./printLayouts/lim.vue"
import ArticlePrintLayoutHs from "./printLayouts/hs.vue"

const ArticlesRepository = RepositoryFactory.get("articles")

export default {
  name: "DialogPreviewFull",
  components: {
    ArticlePrintLayoutLim,
    ArticlePrintLayoutHs,
  },
  props: {
    dialog: Boolean,
    article_id: Number,
    edit_btn: Boolean,
  },
  data() {
    return {
      loading: true,
      article: {},
      show_lines: true,
      previewComponent: "ArticlePrintLayoutLim",
    }
  },
  created() {
    this.previewComponent =
      "ArticlePrintLayout" +
      this.capitalizeFirstLetter(process.env.VUE_APP_PROJECT_ID)
  },
  methods: {
    async fetch() {
      this.loading = true
      const { data } = await ArticlesRepository.getArticle(this.article_id)
      this.article = data
      this.loading = false
    },
    close() {
      this.$emit("close", this.editedItem)
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.fetch()
      } else {
        this.article = {}
        this.hyphenated_text = ""
        this.show_lines = false
        this.votd_ref = ""
      }
    },
  },
}
</script>
