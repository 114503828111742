<template>
  <v-app id="inspire">
    <MenuSetDefault />

    <v-main>
      <div class="text-left">
        <v-sheet color="accent" class="py-5 px-5"><h3>Leseplan</h3></v-sheet>
      </div>

      <v-card class="elevation-0 px-5">
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" fixed-tabs>
            <v-tab
              v-for="(yearTab, index) in instanceData.yearOrder"
              :key="yearTab"
            >
              Jahr {{ index + 1 }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="yearContent in instanceData.yearOrder"
              :key="yearContent"
            >
              <ReadingPlanYear
                v-if="readingPlans.length > 0"
                :leapYearShift="year"
                :readingPlanEntries="
                  readingPlans.filter((e) => e.leap_year_shift == year)
                "
                @planSelected="openDialog"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
      <v-dialog v-model="dialog" width="500" @click:outside="cancelPlan">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ selectedPlan.day }}.
            {{ months[selectedPlan.month - 1]["name"] }} (Schaltjahr +
            {{ year }})
          </v-card-title>

          <v-card-text>
            <v-text-field
              label="Bibellese"
              v-model="selectedPlan.recommendedBibleRef"
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="cancelPlan">
              abbrechen
            </v-btn>
            <v-btn color="primary" @click="savePlan">
              speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import MenuSetDefault from "@/components/layout/MenuSetDefault"
import ReadingPlanYear from "@/components/readingPlan/year"

import projectMixin from "@/mixins/projectMixin"

import { RepositoryFactory } from "@/repositories/RepositoryFactory"
const readingPlansRepository = RepositoryFactory.get("readingPlans")

export default {
  name: "Leseplan",
  mixins: [projectMixin],
  components: {
    MenuSetDefault,
    ReadingPlanYear,
  },
  data() {
    return {
      dialog: false,
      tab: null,
      months: [
        { number: 1, name: "Januar", length: 31 },
        { number: 2, name: "Februar", length: 28 },
        { number: 3, name: "März", length: 31 },
        { number: 4, name: "April", length: 30 },
        { number: 5, name: "Mai", length: 31 },
        { number: 6, name: "Juni", length: 30 },
        { number: 7, name: "Juli", length: 31 },
        { number: 8, name: "August", length: 31 },
        { number: 9, name: "September", length: 30 },
        { number: 10, name: "Oktober", length: 31 },
        { number: 11, name: "November", length: 30 },
        { number: 12, name: "Dezember", length: 31 },
      ],
      readingPlans: [],
      year: null,
      selectedPlan: {
        id: null,
        leapYearShift: null,
        month: 1,
        day: null,
        recommendedBibleRef: null,
      },
    }
  },
  async created() {
    this.fetchReadingPlan()
  },
  methods: {
    async fetchReadingPlan() {
      const { data: readingPlansData } = await readingPlansRepository.get()
      this.readingPlans = readingPlansData
    },
    openDialog(year, month, day, plan = false) {
      this.selectedPlan.leapYearShift = year
      this.selectedPlan.month = month.number
      this.selectedPlan.day = day
      if (plan) {
        this.selectedPlan.id = plan.id
        this.selectedPlan.recommendedBibleRef = plan.recommended_bible_reference
      }
      this.dialog = true
    },
    cancelPlan() {
      this.selectedPlan.id = null
      this.selectedPlan.leapYearShift = null
      this.selectedPlan.month = 1
      this.selectedPlan.day = null
      this.selectedPlan.recommendedBibleRef = null
      this.dialog = false
    },
    async savePlan() {
      if (this.selectedPlan.id) {
        await readingPlansRepository.update(
          this.selectedPlan.id,
          this.selectedPlan.leapYearShift,
          this.selectedPlan.month,
          this.selectedPlan.day,
          this.selectedPlan.recommendedBibleRef
        )
      } else {
        await readingPlansRepository.add(
          this.selectedPlan.leapYearShift,
          this.selectedPlan.month,
          this.selectedPlan.day,
          this.selectedPlan.recommendedBibleRef
        )
      }
      this.dialog = false
      this.fetchReadingPlan()
    },
  },
  watch: {
    tab(newVal) {
      this.year = this.instanceData.yearOrder[newVal]
      if (newVal === this.instanceData.yearOrder.indexOf(0)) {
        this.months[1].length = 29
      } else {
        this.months[1].length = 28
      }
    },
  },
}
</script>
